<template>
  <div class="update-customer">
    <!-- 搜索 -->
    <van-search
      v-model="searchValue"
      show-action
      placeholder="搜索客户姓名或联系电话"
      maxlength="11"
      @clear="onSearch"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div class="update-list">
      <p v-if="customerList.length === 0">暂无数据...</p>
      <van-list
        v-else
        v-model="pull_loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-row v-for="(item, index) in customerList" :key="index">
          <van-col span="24">
            客户姓名：
            <span>{{ item.name }}</span>
          </van-col>
          <van-col span="24">
            联系电话：
            <span>{{ item.mobile }}</span>
          </van-col>
          <van-col span="24" style="line-height: 26px">
            修改原因：
            <span>{{ item.desc }}</span>
          </van-col>
          <van-divider />
          <van-col span="24">
            <van-button
              round
              size="small"
              type="warning"
              class="rejecte-botton"
              @click="personRejected(item.id)"
            >
              <span>驳回</span>
            </van-button>
            <van-button
              round
              size="small"
              type="primary"
              class="through-botton"
              @click="updateCustomerInfo(item.orderid)"
            >
              <span>更改</span>
            </van-button>
          </van-col>
        </van-row>
      </van-list>
    </div>

    <!-- 修改客户信息 -->
    <van-popup v-model="showCustomerInfo" closeable>
      <div class="follow_header">
        <div></div>
        <span>修改客户信息</span>
      </div>
      <van-form @submit="updateCutomer">
        <van-field
          label="客户姓名："
          placeholder="请输入客户姓名"
          required
          v-model="updateCutomerData.name"
          :rules="[{ required: true, message: '请输入客户姓名' }]"
          maxlength="32"
          clearable
        />
        <van-field
          v-model="updateCutomerData.phone"
          label="联系电话："
          type="digit"
          placeholder="请输入电话号码"
          required
          :rules="[
            { required: true, message: '请输入电话' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
          maxlength="11"
          clearable
        />
        <div class="form-reson">
          <span>修改原因：</span>
          <span>{{ updateCutomerData.desc }}</span>
        </div>
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            loading-text="修改"
            :loading="loading"
            >修改</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 全局加载 -->
    <van-overlay :show="isShow">
      <van-loading type="spinner" color="#CC9756" />
    </van-overlay>
  </div>
</template>

<script>
import {
  getCustomerInfo,
  updateCustomerInfo,
  updateCustomerRejected,
} from "@/api/api";
import { Dialog } from "vant";
export default {
  data() {
    return {
      // 按钮加载
      loading: false,
      // 全局加载
      isShow: true,
      // 搜索值
      searchValue: "",
      // 上拉加载
      pull_loading: false,
      finished: false,
      page: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 3, //总共的数据条数
      // 客户信息列表
      customerList: [],
      // 表单数据
      updateCutomerData: {
        seekid: "",
        orderid: "",
        name: "",
        phone: "",
        des: "",
      },
      // 更改信息弹框的显示与隐藏
      showCustomerInfo: false,
    };
  },
  created() {
    // 获取修改客户信息列表
    this.getCustomerInfo();
  },
  methods: {
    // 获取修改客户信息列表
    getCustomerInfo() {
      getCustomerInfo({
        search: this.searchValue,
        page: this.page,
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.length === 0) {
            this.isShow = false;
          }
          // 加载状态结束
          this.pull_loading = false;
          if (this.customerList.length >= res.meta.total) {
            this.finished = true;
            return;
          } else {
            this.customerList = this.customerList.concat(res.data);
            this.page++;
          }
          this.isShow = false;
        }
      });
    },
    // 搜索
    onSearch() {
      this.page = 1;
      this.pull_loading = false;
      this.finished = false;
      this.customerList = [];
      this.getCustomerInfo();
    },
    // 数据加载
    onLoad() {
      this.getCustomerInfo();
    },
    // 更改信息弹窗
    updateCustomerInfo(orderid) {
      this.showCustomerInfo = true;
      this.customerList.map((item) => {
        if (orderid === item.orderid) {
          this.seekid = item.id;
          this.updateCutomerData.orderid = item.orderid;
          this.updateCutomerData.name = item.name;
          this.updateCutomerData.phone = item.mobile;
          this.updateCutomerData.desc = item.desc;
        }
      });
    },
    // 驳回
    personRejected(id) {
      Dialog.confirm({
        message: "客户信息修改驳回？",
        confirmButtonColor: "#f3973b",
      })
        .then(() => {
          // on confirm
          updateCustomerRejected({ seekid: id }).then((res) => {
            if (res.code === 200) {
              this.$toast.success(res.data);
              this.page = 1;
              this.pull_loading = false;
              this.finished = false;
              this.customerList = [];
              this.getCustomerInfo(this.tabType);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    // 更改
    updateCutomer() {
      this.loading = true;
      updateCustomerInfo({
        id: this.updateCutomerData.orderid,
        seekid: this.seekid,
        name: this.updateCutomerData.name,
        mobile: this.updateCutomerData.phone,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast(res.data);
            this.loading = false;
            this.showCustomerInfo = false;
            this.page = 1;
            this.pull_loading = false;
            this.finished = false;
            this.customerList = [];
            this.getCustomerInfo();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-customer {
  margin-top: 26px;
  .update-list {
    margin-top: 10px;
    p {
      font-size: 14px;
      text-align: center;
      margin: 20px 0;
      color: #9a9a9a;
    }
    .van-row {
      min-width: 100%;
      min-height: 200px;
      background-color: #fff;
      margin-bottom: 10px;
      font-size: 13px;
      .van-col {
        font-size: 13px;
        padding: 20px 20px 0px 23px;
        span {
          color: #000;
          font-size: 13px;
        }
      }
      .van-divider {
        border-color: #eaeaea;
        padding: 0 16px;
        padding-top: 20px;
      }
      .van-col:nth-child(5) {
        display: flex;
        flex-direction: inherit;
        justify-content: flex-end;
        padding-top: 0px;
      }
      .van-button {
        width: 80px;
        font-size: 13px;
        margin-right: 16px;
        margin-bottom: 10px;
      }
      .rejecte-botton {
        background-color: #f3983c;
        border: #f3983c;
        span {
          color: #fff;
          font-size: 14px;
        }
      }
      .through-botton {
        background-color: #52c898;
        border: #52c898;
        span {
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
  .van-popup {
    min-width: 345px;
    background: #fff;
    .follow_header {
      width: 100%;
      min-height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      justify-content: flex-start;
      flex-direction: inherit;
      div {
        width: 4px;
        height: 20px;
        background: #f3983c;
        margin: 12px 8px 10px 12px;
      }
      span {
        font-size: 16px;
        color: #343434;
      }
    }
    .van-form {
      .van-field {
        margin-top: 10px;
      }
      /deep/.van-button--info,
      /deep/.van-button--primary {
        background-color: #f3973b;
        border: #f3973b;
      }
      .form-reson {
        padding: 17px;
        color: #343434;
        span:nth-child(2) {
          margin-left: 35px;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
